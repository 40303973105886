import Vue from 'vue';
import VueRouter from 'vue-router';

import AppLayout from './layouts/app.vue';

import HomeView from './views/home.vue';
import AptusHomeView from './views/aptus-home.vue';

import PublicEvaluationsComparativeView from './views/public-evaluations/comparative.vue';
import PublicEvaluationsSchoolView from './views/public-evaluations/school.vue';
import PsuDetailsView from './views/public-evaluations/psu-details.vue';
import TechnicalEducationQualificationView from './views/public-evaluations/technical-education-qualification.vue';
import HigherEducationGraduationView from './views/public-evaluations/higher-education-graduation.vue';

import AdministratorReportView from './views/administrator/administrator-report.vue';
import AttendanceBySchoolView from './views/attendance/by-school.vue';
import AttendanceLastYearComparisonBySchoolView from './views/attendance/last-year-comparison.vue';

import AttendanceByDateBySchoolView from './views/attendance/by-date.vue';
import AttendanceRiskBySchoolView from './views/attendance/risk.vue';

import AttendanceEvolutionLineChartView from './views/attendance/evolution-line-chart.vue';
import DownloadsView from './views/downloads.vue';
import FinancialView from './views/financial.vue';
import ExternalEvaluationsView from './views/external-evaluations.vue';
import AttendanceByStudentView from './views/attendance/by-student.vue';
import AttendanceGeneralPanelBySchool from './views/attendance/general-panels/by-school.vue';
import ComparativeAttendanceView from './views/attendance/comparative.vue';
import ComparativeAttendanceGeneralPanelView from './views/attendance/general-panels/comparative.vue';

import GradesBySchoolView from './views/grades/by-school.vue';
import GradesByGroupView from './views/grades/by-group.vue';
import GradesGroupAverageBySchoolView from './views/grades/group-average/by-school.vue';
import GradesTestCountBySchoolView from './views/grades/test-count/by-school.vue';
import GradesStudentSubjectAveragesView from './views/grades/student/subject-averages.vue';
import GradesStudentSubjectTestsView from './views/grades/student/subject-tests.vue';

import SipDashboardView from './views/dashboard/sip.vue';
import SipDashboardComparativeView from './views/dashboard/sip/comparative.vue';
import SipDashboardBySchoolView from './views/dashboard/sip/by-school.vue';
import SipDashboardBySchoolNetworkView from './views/dashboard/sip/by-school-network.vue';

import ImprovementPlanPerSchool from './views/improvement-plan/per-school.vue';
import improvementPlanComparative from './views/improvement-plan/comparative.vue';

import InternalEvaluationsBySchoolView from './views/internal-evaluations/by-school.vue';
import InternalEvaluationsLevelTestsBySchoolView from './views/internal-evaluations/level-tests/by-school.vue';
import InternalEvaluationsByLevelView from './views/internal-evaluations/by-level.vue';
import InternalEvaluationsLevelTestsByLevelView from './views/internal-evaluations/level-tests/by-level.vue';
import InternalEvaluationsByStudentView from './views/internal-evaluations/by-student.vue';

import AdministratorGoogleClassroomView from './views/google-classroom-reports/comparative.vue';
import GoogleClassroomBySchoolView from './views/google-classroom-reports/by-school.vue';

import ComparativeInternalEvaluationsView from './views/internal-evaluations/comparative.vue';
import ComparativeInternalEvaluationsLevelTestsView from './views/internal-evaluations/level-tests/comparative.vue';

import EnrollmentTablesView from './views/management-indicators/enrollment/enrollment-tables.vue';
import EnrollmentView from './views/management-indicators/enrollment.vue';
import AnnualEnrollmentView from './views/management-indicators/enrollment/annual-enrollment.vue';
import TeacherHoursView from './views/teacher-hours.vue';
import SalesianosLeadershipEvaluationView from './views/management-indicators/salesianos-leadership-evaluation.vue';

import ComparativeColorMapView from './views/comparative/color-map.vue';
import ComparativeHistoricalView from './views/comparative/historical.vue';

// CONVIVENCIA ESCOLAR
import CoexistenceBySchoolView from './views/coexistence/school-coexistence.vue';
import CoexistencePanelBySchoolView from './views/coexistence/general-panels/school-coexistence.vue';
import CoexistencePanelBySchooNetworklView from './views/coexistence/general-panels/school-coexistence-network.vue';
import CoexistencePanelBySchoolCourseView from './views/coexistence/general-panels/school-coexistence-course.vue';
import CoexistencePanelBySchoolStudentView from './views/coexistence/general-panels/school-coexistence-student.vue';
// hijos
import CoexistenceGPSchoolEvolucion from './views/coexistence/por-colegio/school-coexistence-evolucion.vue';
import CoexistenceGPSchoolComparacion from './views/coexistence/por-colegio/school-coexistence-comparacion.vue';
import CoexistenceGPSchoolDistribucion from './views/coexistence/por-colegio/school-coexistence-distribucion.vue';

import CoexistenceGPCurseEvolucion from './views/coexistence/por-curso/curse-coexistence-evolucion.vue';
import CoexistenceGPCurseComparacion from './views/coexistence/por-curso/curse-coexistence-comparacion.vue';
import CoexistenceGPCurseDistribucion from './views/coexistence/por-curso/curse-coexistence-distribucion.vue';
import CoexistenceGPCurseAsignatura from './views/coexistence/por-curso/curse-coexistence-asignatura.vue';





/*import GradesBySchoolView from './views/grades/by-school.vue';
import GradesByGroupView from './views/grades/by-group.vue';
import GradesGroupAverageBySchoolView from './views/grades/group-average/by-school.vue';
import GradesTestCountBySchoolView from './views/grades/test-count/by-school.vue';
import GradesStudentSubjectAveragesView from './views/grades/student/subject-averages.vue';
import GradesStudentSubjectTestsView from './views/grades/student/subject-tests.vue'; */




import SchoolColorMapView from './views/school/color-map.vue';
import SchoolHistoricalView from './views/school/historical.vue';

import LearningLevelsView from './views/school/learning-levels.vue';

import StudentListView from './views/students/list.vue';
import StudentReportView from './views/students/student-report.vue';

import GroupReportView from './views/groups/report.vue';
import SchoolReportView from './views/school/school-report.vue';

import ProQuoteView from './views/pro-quote.vue';

import SettingsView from './views/settings.vue';

import ResetPasswordView from './views/reset-password.vue';

import store from './store';

import VersionApi from './api/app-version';
import PulsoApi from './api/pulso';

Vue.use(VueRouter);

/* eslint-disable max-statements, no-undef */
async function fetchUser(to, _, next) {
  try {
    const userInfo = await store.dispatch('auth/fetchUserInfo');
    dataLayer.push({
      event: 'Authentication',
      userType: userInfo.plan === 'free' ? 'free' : 'pro',
      email: userInfo.email,
      preferredSchoolId: userInfo.preferred_school_id,
    });
  } catch (e) {
    dataLayer.push({
      event: 'Authentication',
      userType: 'none',
    });
    return next();
  }
  return next();
}
/* eslint-enable max-statements */

async function redirectToPulso() {
  try {
    const { body } = await PulsoApi.requestLandingUrl(store.state.auth.userInfo.email);
    window.open(body.url, '_blank');
  } catch (e) {
    window.open('https://pulsoescolar.cl', '_blank');
  }
}

function requireAuth(to, from, next) {
  const condition = !!localStorage.getItem('token_radar');
  if (condition) {
    next();
  } else {
    next('/');
  }
}

function requireSipDashboardPermission(to, from, next) {
  if (store.state.auth.userInfo.permissions.sip_dashboard) {
    next();
  } else {
    next('/');
  }
}

function requirePermission(name) {
  return (_, __, next) => {
    const { userInfo } = store.state.auth;
    if (userInfo && userInfo.permissions[name]) {
      next();
      return true;
    }
    next('/');
    return false;
  };
}

function withPrefix(prefix, routes) {
  return routes.map((route) => {
    route.path = prefix + route.path;
    return route;
  });
}

function redirectOnMissingParams(next, params, requiredParams) {
  if (requiredParams.every(p => params.hasOwnProperty(p))) {
    next();
  } else {
    next('/');
  }
}

function redirectOnIncorrectQueryParams(to, next, requiredParamValues) {
  if (Object.entries(requiredParamValues).every(([k, v]) => to.query[k] && to.query[k] === v)) {
    next();
  } else {
    next('/');
  }
}

const routes = [
  {
    path: '/reset-password',
    name: 'resetPassword',
    component: ResetPasswordView,
  },
  {
    path: '/',
    name: 'home',
    component: HomeView,
    beforeEnter: fetchUser,
  },
  {
    path: '/aptus',
    name: 'aptus',
    component: AptusHomeView,
    beforeEnter:
      (to, _, next) => {
        redirectOnIncorrectQueryParams(to, next, { 'ref': 'YXB0dXNjbGllbnQ' });
      },
  },
  {
    path: '/login',
    name: 'login',
    component: HomeView,
    props: { openLogin: true },
    beforeEnter: fetchUser,
  },
  {
    path: '/register',
    name: 'register',
    component: HomeView,
    props: { openRegister: true },
    beforeEnter: fetchUser,
  },
  {
    path: '/app',
    name: 'app',
    component: AppLayout,
    beforeEnter: fetchUser,
    children: [
      {
        path: 'downloads',
        name: 'downloads',
        component: DownloadsView,
      },
      {
        path: 'financial',
        name: 'financial',
        component: FinancialView,
      },
      {
        path: 'external-evaluations',
        name: 'external-evaluations',
        component: ExternalEvaluationsView,
      },
      {
        path: 'comparative/:year?/:group?/:schools?',
        name: 'comparativeEntry',
        beforeEnter: (to, from, next) => {
          if (!to.params.year || !to.params.group || !to.params.schools) {
            next('/');
          } else {
            next({ name: 'comparativeColorMap', params: to.params });
          }
        },
      },
      {
        path: 'comparative',
        name: 'comparative',
        component: PublicEvaluationsComparativeView,
        children: [
          {
            path: 'color-map/:year?/:group?/:schools?',
            name: 'comparativeColorMap',
            meta: { title: 'SIMCE - PSU - Otros Comparativo' },
            component: ComparativeColorMapView,
            beforeEnter: (to, _, next) => {
              redirectOnMissingParams(next, to.params, ['year', 'group', 'schools']);
            },
          },
          {
            path: 'historical/:year?/:group?/:schools?',
            name: 'comparativeHistorical',
            meta: { title: 'SIMCE - PSU - Otros Comparativo' },
            component: ComparativeHistoricalView,
            beforeEnter: (to, _, next) => {
              redirectOnMissingParams(next, to.params, ['year', 'group', 'schools']);
            },
          },
        ],
      },
      {
        path: 'school',
        name: 'school',
        component: PublicEvaluationsSchoolView,
        children: [
          {
            path: 'color-map/:year?/:school?',
            name: 'schoolColorMap',
            meta: { title: 'SIMCE - PSU - Otros por Colegio' },
            component: SchoolColorMapView,
            beforeEnter: (to, _, next) => {
              redirectOnMissingParams(next, to.params, ['year', 'school']);
            },
          },
          {
            path: 'historical/:group?/:school?',
            name: 'schoolHistorical',
            meta: { title: 'SIMCE - PSU - Otros por Colegio' },
            component: SchoolHistoricalView,
            beforeEnter: (to, from, next) => {
              redirectOnMissingParams(next, to.params, ['group', 'school']);
            },
          },
          {
            path: 'learning-levels/:school?',
            name: 'learningLevels',
            meta: { title: 'SIMCE - PSU - Otros por Colegio' },
            component: LearningLevelsView,
            beforeEnter: (to, from, next) => {
              redirectOnMissingParams(next, to.params, ['school']);
            },
          },
        ],
      },
      {
        path: 'psu-details',
        name: 'psuDetails',
        meta: { title: 'PSU (en detalle)' },
        component: PsuDetailsView,
      },
      {
        path: 'technical-education-qualification',
        name: 'technicalEducationQualification',
        meta: { title: 'Titulación TP' },
        component: TechnicalEducationQualificationView,
      },
      {
        path: 'higher-education-graduation',
        name: 'higherEducationGraduation',
        meta: { title: 'Egreso a ed. superior' },
        component: HigherEducationGraduationView,
      },
      {
        path: 'quotation',
        name: 'proQuote',
        component: ProQuoteView,
      },
      ...withPrefix('attendance/', [
        {
          path: 'by-school',
          name: 'attendanceBySchool',
          component: AttendanceBySchoolView,
          children: [
            {
              path: 'general/:year?/:school?',
              name: 'attendanceGeneralPanelBySchool',
              meta: { title: 'Asistencia por Colegio' },
              component: AttendanceGeneralPanelBySchool,
              beforeEnter: (to, from, next) => {
                redirectOnMissingParams(next, to.params, ['year', 'school']);
              },
            },
            {
              path: 'evolution-line-chart/:year?/:school?',
              name: 'attendanceEvolutionLineChart',
              meta: { title: 'Evolución mensual' },
              component: AttendanceEvolutionLineChartView,
              beforeEnter: (to, _, next) => {
                redirectOnMissingParams(next, to.params, ['year', 'school']);
              },
            },
            {
              path: 'last-year-comparison/:year?/:school?',
              name: 'attendanceLastYearComparisonBySchool',
              meta: { title: 'Comparación Año Anterior' },
              component: AttendanceLastYearComparisonBySchoolView,
              beforeEnter: (to, _, next) => {
                redirectOnMissingParams(next, to.params, ['year', 'school']);
              },
            },
            {
              path: 'by-date/:year?/:school?',
              name: 'attendanceByDateBySchool',
              meta: { title: 'Por Fecha' },
              component: AttendanceByDateBySchoolView,
              beforeEnter: (to, _, next) => {
                redirectOnMissingParams(next, to.params, ['year', 'school']);
              },
            },
            {
              path: 'risk/:year?/:school?',
              name: 'attendanceRiskBySchool',
              meta: { title: 'Riesgo' },
              component: AttendanceRiskBySchoolView,
              beforeEnter: (to, _, next) => {
                redirectOnMissingParams(next, to.params, ['year', 'school']);
              },
            },
          ],
        },
        {
          path: 'by-student/:year?/:school?',
          name: 'attendanceByStudent',
          component: AttendanceByStudentView,
          beforeEnter: (to, _, next) => {
            redirectOnMissingParams(next, to.params, ['year', 'school']);
          },
        },
        {
          path: 'comparative',
          name: 'comparativeAttendance',
          component: ComparativeAttendanceView,
          children: [
            {
              path: 'general/:year?',
              name: 'comparativeAttendanceGeneralPanel',
              meta: { title: 'Asistencia Comparativo' },
              component: ComparativeAttendanceGeneralPanelView,
              beforeEnter: (to, _, next) => {
                redirectOnMissingParams(next, to.params, ['year']);
              },
            },
          ],
        },
      ]),

      ...withPrefix('coexistence/', [
        {
          path: 'school-coexistence',
          name: 'coexistenceBySchool',
          component: CoexistenceBySchoolView,
          children: [
            {
              path: 'general-panels/colegio/:year?/:month?/:school?',
              name: 'coexistenceGeneralPanelsSchoolCoexistence',
              meta: { title: 'Convivencia escolar por Colegio' },
              component: CoexistencePanelBySchoolView,
              beforeEnter: (to, _, next) => {
                to.params.month = 0;
                redirectOnMissingParams(next, to.params, ['year', 'month', 'school']);
              },
            },
            {
              path: 'general-panels-evolution/:year?/:month?/:school?',
              name: 'coexistenceGPSchoolEvolucion',
              meta: { title: 'Convivencia escolar evolucion' },
              component: CoexistenceGPSchoolEvolucion,
              beforeEnter: (to, _, next) => {
                to.params.month = 0;
                redirectOnMissingParams(next, to.params, ['year', 'month', 'school']);
              },
            },
            {
              path: 'general-panels-comparacion/:year?/:month?/:school?',
              name: 'coexistenceGPSchoolComparacion',
              meta: { title: 'Convivencia escolar comparacion' },
              component: CoexistenceGPSchoolComparacion,
              beforeEnter: (to, _, next) => {
                to.params.month = 0;
                redirectOnMissingParams(next, to.params, ['year', 'month', 'school']);
              },
            },
            {
              path: 'general-panels-distribucion/:year?/:month?/:school?',
              name: 'coexistenceGPSchoolDistribucion',
              meta: { title: 'Convivencia escolar distribucion' },
              component: CoexistenceGPSchoolDistribucion,
              beforeEnter: (to, _, next) => {
                to.params.month = 0;
                redirectOnMissingParams(next, to.params, ['year', 'month', 'school']);
              },
            },

            {
              path: 'general-panels-network/:year?/:month?/:school?',
              name: 'coexistenceBySchoolNetwork',
              meta: { title: 'Convivencia escolar red Colegios' },
              component: CoexistencePanelBySchooNetworklView,
              beforeEnter: (to, _, next) => {
                to.params.month = 0;
                redirectOnMissingParams(next, to.params, ['year', 'month', 'school']);
              },
            },
            {
              path: 'general-panels-course/curso/:year?/:month?/:school?',
              name: 'coexistenceBySchoolCourse',
              meta: { title: 'Convivencia escolar por Curso' },
              component: CoexistencePanelBySchoolCourseView,
              beforeEnter: (to, _, next) => {
                to.params.month = 0;
                redirectOnMissingParams(next, to.params, ['year', 'month', 'school']);
              },
            },

            {
              path: 'general-panels-evolution/:year?/:month?/:school?',
              name: 'coexistenceGPCurseEvolucion',
              meta: { title: 'Convivencia escolar evolucion' },
              component: CoexistenceGPCurseEvolucion,
              beforeEnter: (to, _, next) => {
                to.params.month = 0;
                redirectOnMissingParams(next, to.params, ['year', 'month', 'school']);
              },
            },
            {
              path: 'general-panels-comparacion/:year?/:month?/:school?',
              name: 'coexistenceGPCurseComparacion',
              meta: { title: 'Convivencia escolar comparacion' },
              component: CoexistenceGPCurseComparacion,
              beforeEnter: (to, _, next) => {
                to.params.month = 0;
                redirectOnMissingParams(next, to.params, ['year', 'month', 'school']);
              },
            },
            {
              path: 'general-panels-distribucion/:year?/:month?/:school?',
              name: 'coexistenceGPCurseDistribucion',
              meta: { title: 'Convivencia escolar distribucion' },
              component: CoexistenceGPCurseDistribucion,
              beforeEnter: (to, _, next) => {
                to.params.month = 0;
                redirectOnMissingParams(next, to.params, ['year', 'month', 'school']);
              },
            },
            // {
            //   path: 'general-panels-asignaruta/:year?/:month?/:school?',
            //   name: 'coexistenceGPCurseAsignatura',
            //   meta: { title: 'Convivencia escolar asignaruta' },
            //   component: CoexistenceGPCurseAsignatura,
            //   beforeEnter: (to, _, next) => {
            //     to.params.month = 0;
            //     redirectOnMissingParams(next, to.params, ['year', 'month', 'school']);
            //   },
            // },

            // {
            //   path: 'general-panels-student/:year?/:month?/:school?',
            //   name: 'coexistenceBySchoolStudent',
            //   meta: { title: 'Convivencia escolar por estudiante' },
            //   component: CoexistencePanelBySchoolStudentView,
            //   beforeEnter: (to, _, next) => {
            //     to.params.month = 0;
            //     redirectOnMissingParams(next, to.params, ['year', 'month', 'school']);
            //   },
            // },
          ],
        },
      ]),

      ...withPrefix('grades/', [
        {
          path: 'by-school',
          name: 'gradesBySchool',
          component: GradesBySchoolView,
          children: [
            {
              path: 'group-average/:year?/:month?/:school?',
              name: 'gradesGroupAverageBySchool',
              meta: { title: 'Promedio de calificaciones por Colegio' },
              component: GradesGroupAverageBySchoolView,
              beforeEnter: (to, _, next) => {
                to.params.month = 0;
                redirectOnMissingParams(next, to.params, ['year', 'month', 'school']);
              },
            },
            {
              path: 'test-count/:year?/:month?/:school?',
              name: 'gradesTestCountBySchool',
              meta: { title: 'Promedio de calificaciones por Colegio' },
              component: GradesTestCountBySchoolView,
              beforeEnter: (to, _, next) => {
                redirectOnMissingParams(next, to.params, ['year', 'month', 'school']);
              },
            },
          ],
        },
        {
          path: 'by-group',
          name: 'gradesByGroup',
          component: GradesByGroupView,
          children: [
            ...withPrefix('student/', [
              {
                path: 'subject-tests/:year?/:school?/:group?',
                name: 'gradesStudentSubjectTests',
                meta: { title: 'Calificaciones por Curso' },
                component: GradesStudentSubjectTestsView,
                beforeEnter: (to, from, next) => {
                  redirectOnMissingParams(next, to.params, ['year', 'school', 'group']);
                },
              },
              {
                path: 'subject-averages/:year?/:school?/:group?',
                name: 'gradesStudentSubjectAverages',
                meta: { title: 'Calificaciones por Curso' },
                component: GradesStudentSubjectAveragesView,
                beforeEnter: (to, from, next) => {
                  redirectOnMissingParams(next, to.params, ['year', 'school', 'group']);
                },
              },
            ]),
          ],
        },
      ]),
      ...withPrefix('improvement-plan/', [
        {
          path: 'school/:school?/:year?',
          name: 'improvementPlanPerSchool',
          component: ImprovementPlanPerSchool,
          meta: { title: 'Seguimiento PME Por Colegio' },
          beforeEnter: (to, _, next) => {
            redirectOnMissingParams(next, to.params, ['year', 'school']);
          },
        },
        {
          path: 'comparative/:year?',
          name: 'improvementPlanComparative',
          component: improvementPlanComparative,
          meta: { title: 'Seguimiento PME Comparativo' },
          beforeEnter: (to, _, next) => {
            redirectOnMissingParams(next, to.params, ['year']);
          },
        },
      ]),
      ...withPrefix('dashboard/', [
        {
          path: 'sip',
          name: 'sipDashboard',
          component: SipDashboardView,
          beforeEnter: requireSipDashboardPermission,
          children: [
            {
              path: 'comparative/:year?/:schools?',
              name: 'sipDashboardComparative',
              meta: { title: 'Dashboard SIP' },
              component: SipDashboardComparativeView,
              beforeEnter: (to, _, next) => {
                redirectOnMissingParams(next, to.params, ['year', 'schools']);
              },
            },
            {
              path: 'by-school/:school?',
              name: 'sipDashboardBySchool',
              meta: { title: 'Dashboard SIP' },
              component: SipDashboardBySchoolView,
              beforeEnter: (to, _, next) => {
                redirectOnMissingParams(next, to.params, ['school']);
              },
            },
            {
              path: 'by-school-network/:schoolNetwork?',
              name: 'sipDashboardBySchoolNetwork',
              meta: { title: 'Dashboard SIP' },
              component: SipDashboardBySchoolNetworkView,
              beforeEnter: (to, _, next) => {
                redirectOnMissingParams(next, to.params, ['schoolNetwork']);
              },
            },
          ],
        },
      ]),
      {
        path: 'enrollment',
        name: 'enrollment',
        component: EnrollmentView,
        children: [
          {
            path: 'annual/:schools?',
            name: 'annualEnrollment',
            meta: { title: 'Matrícula - Ocupación' },
            component: AnnualEnrollmentView,
            beforeEnter: (to, _, next) => {
              redirectOnMissingParams(next, to.params, ['schools']);
            },
          },
          {
            path: 'by-level/:enrollmentDisplay?/:year?',
            name: 'enrollmentByLevel',
            meta: { title: 'Seguimiento matrícula y ocupación' },
            beforeEnter: (to, _, next) => {
              redirectOnMissingParams(next, to.params, ['enrollmentDisplay', 'year']);
            },
            component: EnrollmentTablesView,
          },
        ],
      },
      {
        path: 'group-report/:school?/:year?',
        name: 'groupReport',
        meta: { title: 'Ficha de curso' },
        component: GroupReportView,
        beforeEnter: ({ params }, _, next) => {
          redirectOnMissingParams(next, params, ['school', 'year']);
        },
      },
      {
        path: 'administrator-report/:year?',
        name: 'administratorReport',
        meta: { title: 'Ficha sostenedor' },
        component: AdministratorReportView,
        beforeEnter: ({ params }, _, next) => {
          redirectOnMissingParams(next, params, ['year']);
        },
      },
      {
        path: 'school-report/:school?/:year?',
        name: 'schoolReport',
        meta: { title: 'Ficha de colegio' },
        component: SchoolReportView,
        beforeEnter: ({ params }, _, next) => {
          redirectOnMissingParams(next, params, ['school', 'year']);
        },
      },
      ...withPrefix('internal-evaluations/', [
        {
          path: 'by-school',
          name: 'internalEvaluationsBySchool',
          component: InternalEvaluationsBySchoolView,
          children: [
            {
              path: 'level-tests/:year?/:month?/:school?',
              name: 'internalEvaluationsLevelTestsBySchool',
              meta: { title: 'Promedio de Pruebas de Nivel por Colegio' },
              component: InternalEvaluationsLevelTestsBySchoolView,
              beforeEnter: (to, from, next) => {
                redirectOnMissingParams(next, to.params, ['year', 'month', 'school']);
              },
            },
          ],
        },
        {
          path: 'comparative',
          name: 'comparativeInternalEvaluations',
          component: ComparativeInternalEvaluationsView,
          children: [
            {
              path: 'level-tests/:year?/:month?',
              name: 'comparativeInternalEvaluationsLevelTests',
              meta: { title: 'Promedio de Pruebas de Nivel Comparativo' },
              component: ComparativeInternalEvaluationsLevelTestsView,
              beforeEnter: (to, from, next) => {
                redirectOnMissingParams(next, to.params, ['year', 'month']);
              },
            },
          ],
        },
        {
          path: 'by-level',
          name: 'internalEvaluationsByLevel',
          component: InternalEvaluationsByLevelView,
          children: [
            {
              path: 'level-tests/:year?/:month?/:school?/:group?',
              name: 'internalEvaluationsLevelTestByLevel',
              meta: { title: 'Promedio de Pruebas de Nivel por Nivel' },
              component: InternalEvaluationsLevelTestsByLevelView,
              beforeEnter: (to, from, next) => {
                redirectOnMissingParams(next, to.params, ['year', 'month', 'school', 'group']);
              },
            },
          ],
        },
        {
          path: 'by-student/:year?/:month?/:school?/:group?',
          name: 'internalEvaluationsByStudent',
          component: InternalEvaluationsByStudentView,
          beforeEnter: ({ params }, _, next) => {
            redirectOnMissingParams(next, params, ['year', 'month', 'school', 'group']);
          },
        },
      ]),
      ...withPrefix('google-classroom/', [
        {
          path: 'comparative',
          name: 'administratorGoogleClassroom',
          meta: { title: 'Reportes de Google Classroom Red de Colegios' },
          component: AdministratorGoogleClassroomView,
        },
        {
          path: 'by-school',
          name: 'googleClassroomBySchool',
          meta: { title: 'Reportes de Google Classroom por Colegio' },
          component: GoogleClassroomBySchoolView,
        },
      ]),
      {
        path: 'settings',
        name: 'settings',
        props: ({ query }) => ({ ...(query.id ? { openSchoolId: query.id } : {}) }),
        component: SettingsView,
        beforeEnter: requireAuth,
      },
      {
        path: 'teacher-hours/:schools?',
        name: 'teacherHours',
        meta: { title: 'Horas de contrato de los docentes de aula por estudiante' },
        component: TeacherHoursView,
        beforeEnter: (to, from, next) => {
          redirectOnMissingParams(next, to.params, ['schools']);
        },
      },
      {
        path: 'student-list/:school?/',
        name: 'studentList',
        meta: { title: 'Lista de alumnos' },
        component: StudentListView,
        beforeEnter: (to, _, next) => {
          redirectOnMissingParams(next, to.params, ['school']);
        },
      },
      {
        path: 'student-report/:student?/',
        name: 'studentReport',
        meta: { title: 'Ficha de alumno' },
        component: StudentReportView,
        beforeEnter: (to, _, next) => {
          if (requirePermission('student_report')(to, _, next)) {
            redirectOnMissingParams(next, to.params, ['student']);
          }
        },
      },
      {
        path: 'leadership-evaluation/:year?/:schools?',
        name: 'salesianosLeadershipEvaluation',
        meta: { title: 'Liderazgo Directivo' },
        component: SalesianosLeadershipEvaluationView,
        beforeEnter: (to, _, next) => {
          redirectOnMissingParams(next, to.params, ['year', 'schools']);
        },
      },
      {
        path: 'pulso-escolar',
        name: 'pulsoEscolar',
        meta: { title: 'Pulso Escolar' },
        beforeEnter: redirectToPulso,
      },
      // LEGACY ROUTES
      // TO SUPPORT OLD URLS
      {
        path: 'color-map/:year?/:group?/:schools?',
        name: 'legacyColorMap',
        beforeEnter: (to, from, next) => {
          redirectOnMissingParams(next, to.params, ['year', 'group', 'schools']);
        },
      },
      {
        path: 'historical/:year?/:group?/:schools?',
        name: 'legacyHistorical',
        beforeEnter: (to, from, next) => {
          redirectOnMissingParams(next, to.params, ['year', 'group', 'schools']);
        },
      },
      {
        path: 'enrollment/:schools?',
        name: 'legacyEnrollment',
        meta: { title: 'Matrícula - Ocupación' },
        component: EnrollmentView,
        beforeEnter: (to, from, next) => {
          redirectOnMissingParams(next, to.params, ['schools']);
        },
      },
    ],
  },
  {
    path: '*',
    redirect: '/',
  },
];

const router = new VueRouter({
  routes, // short for routes: routes
  // mode: 'history',
  scrollBehavior(_, __, savedPosition) {
    if (savedPosition) return savedPosition;

    return { x: 0, y: 0 };
  },
});

function handleOutdatedVersions() {
  VersionApi.getAppVersion().then(({ body }) => {
    const latestVersion = body.version;
    const currentVersion = localStorage.getItem('version');
    store.commit('ui/SET_LATEST_VERSION', latestVersion);
    if (currentVersion && latestVersion !== currentVersion) {
      store.commit('ui/SET_DIALOG_STATE', { dialog: 'versionUpdate', state: true });
    } else {
      localStorage.setItem('version', latestVersion);
    }
  });
}

router.beforeEach((to, from, next) => {
  if (to.name !== from.name) {
    store.commit('results/SET_RESULTS', null);
    store.commit('options/SET_INDICATORS', []);
    handleOutdatedVersions();
  }
  next();
});

export default router;
