<template>
  <div class="wrapper-graph">
    <!-- <template v-if="renderGraph">
      <div class="contenCanvas">
        <div>
          <canvas id="barChartCanvas"></canvas>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="no-results">
        <img src="/noResultados.svg">
      </div>
    </template> -->
    <div>      
      <canvas id="barChartCanvas"></canvas>
    </div>
  </div>
</template>

<script>
import AuthMixin from '../mixins/auth';

export default {
  name: 'BarGraphGroup',
  mixins: [
    AuthMixin,
  ],
  props: {
    dataGrph: {
      type: Object,
      default: null,
    },
    textVert: {
      type: String,
      default: '',
      required: true,
    },
    totalPercent: { // New prop for percentage values
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      renderGraph: false,
      chartInstance: null,
    };
  },
  mounted() {
    this.createChart();
  },
  methods: {     
  handleDatasetVisibility(index) {
   console.log('ingreso a actulizar');
    const chart = this.$refs.barGraph.chartInstance;
    // Iterar sobre todos los datasets y ocultarlos
    chart.data.datasets.forEach((dataset, i) => {
      dataset.hidden = i !== index;  // Mostrar solo el dataset seleccionado
    });
    chart.update();  // Actualizar el gráfico
  },

  createChart() {
    const ctx = document.getElementById('barChartCanvas').getContext('2d');

    const options = {
      layout: {
        padding: {
          top: 30,
        },
      },
      tooltips: {
        displayColors: true,
        callbacks: {
          mode: 'x',
        },
      },
      scales: {
        xAxes: [{
          gridLines: {
            display: false,
          },
        }],
        yAxes: [{
          ticks: {
            beginAtZero: true,
          },
          gridLines: {
            borderDash: ['8', '4'],
            color: '#DCDCDC',
          },
        }],
      },
      responsive: true,
      maintainAspectRatio: true,
      legend: {
        position: 'bottom',
        labels: {
          usePointStyle: true,
        },
        onClick: (e) => e.stopPropagation(),
        // onClick: (event, legendItem) => {
        //   // Obtén el índice del dataset que se hace clic
        //  console.log('VLIDANDO LEGEN', legendItem);
        //     const index = legendItem.datasetIndex;
        //     const chart = this.chartInstance;

        //     // Iterate through all datasets and hide them
        //     chart.data.datasets.forEach((dataset, i) => {
        //       // Hide all datasets except the selected one
        //       dataset.hidden = i !== index;
        //     });

        //     // Update the chart to reflect the changes
        //     chart.update();
        // },
      },
      hover: {
        animationDuration: 1,
      },
      animation: {
        duration: 1500,
        onComplete: () => {
          const chartInstance = this.chartInstance;
          const ctx = chartInstance?.ctx;

          if (ctx) {
            ctx.font = Chart.helpers.fontString(
              Chart.defaults.global.defaultFontSize,
              Chart.defaults.global.defaultFontStyle,
              Chart.defaults.global.defaultFontFamily
            );
            ctx.textAlign = 'center';
            ctx.textBaseline = 'middle';
            ctx.fillStyle = '#000';
            ctx.font = 'bold 12px Helvetica, Arial, sans-serif';

            // Guardar posiciones de cada grupo de barras
            const groupCenters = new Array(this.dataGrph.labels.length).fill(0);

            // Dibujar porcentaje individual sobre cada barra
            // this.dataGrph.datasets.forEach((dataset, i) => {
            //   const color = i === 0 ? '#fff' : '#000'; // Color del texto
            //   ctx.fillStyle = color;
            //   const meta = chartInstance.controller.getDatasetMeta(i);

            //   if (chartInstance.isDatasetVisible(i)) {
            //     meta.data.forEach((bar, index) => {
            //       const data = dataset.data?.[index];
            //       if (data !== undefined) {
            //         const percentage = data + '%';
            //         const yPosition = bar._model.y + (data < 0 ? 50 : 50);
            //         ctx.fillText(percentage, bar._model.x, yPosition);

            //         groupCenters[index] += bar._model.x;
            //       }
            //     });
            //   }
            // });

            // Dibujar el porcentaje total
            groupCenters.forEach((totalXPosition, index) => {
              const total = this.totalPercent?.[index];
              if (total !== undefined) {
                let xPosition = 0;
                this.chartInstance.data.datasets.forEach((dataset, i) => {
                  const meta = this.chartInstance.getDatasetMeta(i);
                  const bar = meta.data[index];
                  if (bar && bar._model) {
                    xPosition = bar._model.x;
                  }
                });
                const yPosition = this.chartInstance.scales['y-axis-0'].top - 40;
                ctx.fillText(total + '%', xPosition, yPosition);
              }
            });
          }
        },
      },
    };

    // Crear la instancia del gráfico
    this.chartInstance = new Chart(ctx, {
      type: 'bar',
      data: this.dataGrph,
      options: options,
    });

    this.chartInstance.update();
  }, 
  
  
  },
};


</script>

<style>
  .wrapper-graph {
    min-height: 330px;
  }
  .no-results {
    text-align: center;
  }
  .contenCanvas {
    max-height: 500px;
    min-height: 300px;
  }
</style>
