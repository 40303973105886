<template>
  <paywall
    :permitted="isLoggedIn && hasGradesPermission"    
    :free="isFreeUser"
    restricted
    transparent>

    <rdr-results-header        
      year-picker
      show-school-picker
      show-school-year-picker>
    </rdr-results-header>
    <router-view/>

    <div class="content-filters-r">
      <a class="tab_phbU" :class="{ active_JFKP: activeTab === null }" @click="toggleDatasetVisibility(null, true)">
        Todos
      </a>
      <a class="tab_phbU" :class="{ active_JFKP: activeTab === 2 }" @click="toggleDatasetVisibility(2)">
        Positivos
      </a>
      <a class="tab_phbU" :class="{ active_JFKP: activeTab === 0 }" @click="toggleDatasetVisibility(0)">
        Negativos
      </a>
      <a class="tab_phbU" :class="{ active_JFKP: activeTab === 1 }" @click="toggleDatasetVisibility(1)">
        Neutros
      </a>
    </div>
    <div id="content-attendance" class="results-colormap">      
      <div class="content-result">
        <div class="panel-content-result">
          <div class="ttl-panel">Total registros</div>
          <div class="result-panel"> {{ this.results.totals.total }}</div>
        </div>
        <div class="panel-content-result">
          <div class="ttl-panel">Registros positivos</div>
          <div class="result-panel"> {{ this.results.totals.positivos }}</div>
        </div>
        <div class="panel-content-result">
          <div class="ttl-panel">Registros negativos</div>
          <div class="result-panel"> {{ this.results.totals.negativos }}</div>
        </div>
        <div class="panel-content-result">
          <div class="ttl-panel">Registros neutros</div>
          <div class="result-panel"> {{ this.results.totals.neutros }}</div>
        </div>
      </div>

      <div class="conten-filters-title-panel">
        <div class="ttl-panel">Distribución de tipos de registros por colegio</div>
      </div>
      <div v-if="loadGraph" class="contenGraficas">
        <div v-if="loading">
          <transition name="fade">
            <loading-overlay/>
          </transition>
        </div>
        <div v-else>
          <div class="content-justify-content">
            <template v-if="loadGraph">
              <bar-graph
                ref="barGraph"
                :data-grph="results"
                :text-vert="txtVert"
                :total-percent="totalPercent"
              />
            </template>
          </div>
        </div>
      </div>
    </div>
    
  </paywall>
</template>

<script type="text/javascript" src="https://cdn.jsdelivr.net/npm/chart.js"></script>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
// import ColorMapFooter from '../../../components/results/color-map-footer.vue';
import AuthMixin from '../../../mixins/auth';
import PermissionMixin from '../../../mixins/permission';
import ResultsMixin from '../../../mixins/results';
import MockDataMixin from '../../../mixins/mock-data';
import barGraph from '../../../components/bar-graph-group.vue';
import ResultsHeader from '../../../components/results/results-attendance-header.vue';

/* eslint-disable camelcase */

export default {
  name: 'CoexistencePanelBySchooNetworklView',
  components: {
    //ColorMapFooter,
    'rdr-results-header': ResultsHeader,
    barGraph,
  },
  mixins: [AuthMixin, MockDataMixin, PermissionMixin, ResultsMixin],
  data() {
    return {
      loadGraph: true,
      activeTab: null,
      txtVert: 'Valores del Dataset',
      totalPercent:[100, 100],
    };
  },
  computed: {
    ...mapState('options', ['chosenYear']),
    ...mapState('results', {
      resultsLoading: 'loading',
    }),
    ...mapGetters('results', {
      resultsGetter: 'results',
    }),
    loading() { return !!this.resultsLoading; },
    results() {
      if (this.resultsGetter.length === 0) {
        return {
          totals: {
            total: 0,
            positivos: 0,
            negativos: 0,
            neutros: 0,
          },
          labels: [],
          datasets: [],
        };
      }
      return this.resultsGetter[0];
    }
  },
  methods: {
    ...mapActions('options', ['setReportParams']),
    setViewParams() {
      this.setReportParams({ 'school_ids': true, 'years': true });
      this.setReportKey('behavior-network');
    },

    toggleDatasetVisibility(index, showAll = false) {
      if (index === null) {
        // Si se hace clic en "Todos", desactivar cualquier selección individual
        this.activeTab = null;
      } else {
        // Si se selecciona un botón específico, asignar su índice como activeTab
        // this.activeTab = this.activeTab === index ? null : index; // Alternar selección
        this.activeTab = index;
      }

      const chart = this.$refs.barGraph.chartInstance;  
      // Si el parámetro showAll es true, mostrar todos los datasets
      if (showAll) {
          chart.data.datasets.forEach(dataset => {
              dataset.hidden = false;  // Mostrar todos los datasets
          });
          chart.update();
          return;  // Salir de la función después de mostrar todos los datasets
      }

        // Verificar si todos los datasets están visibles
        const allVisible = chart.data.datasets.every(dataset => !dataset.hidden);    
        // Verificar si el dataset seleccionado ya es el único visible
        const selectedDataset = chart.data.datasets[index];
        const onlyOneVisible = chart.data.datasets.filter(dataset => !dataset.hidden).length === 1 && !selectedDataset.hidden;

        // Si todos los datasets están visibles y se hace clic en un dataset
        if (allVisible) {
            // Si el mismo dataset ya está visible, mostrar todos los datasets
            if (onlyOneVisible) {
                chart.data.datasets.forEach(dataset => {
                    dataset.hidden = false;  // Mostrar todos los datasets
                });
            } else {
                // Si el dataset no está visible, ocultar todos los demás y mostrar solo el seleccionado
                chart.data.datasets.forEach((dataset, i) => {
                    dataset.hidden = i !== index;  // Solo mostrar el dataset seleccionado
                });
            }
        } else {
            // Si no todos los datasets están visibles, mostrar el dataset seleccionado y ocultar los demás
            chart.data.datasets.forEach((dataset, i) => {
                // Si el índice es el seleccionado, mostrarlo
                if (i === index) {
                    dataset.hidden = false;
                } else {
                    dataset.hidden = true;  // Ocultar los demás
                }
            });
        }
    // Actualizar el gráfico para reflejar los cambios
    chart.update();
    }
  },
};
</script>
<style>
  /*@import "../../../styles/app/variables";*/
  .content-justify-content {
    width: 48%;
    margin: 0 auto;
    position: relative;
  }

  .content-justify-content.wrapper-graph {
    position: relative;
  }

  .content-filters-r {
    display: flex;
    margin-bottom: 30px;
  }

  div#content-attendance {
      background: #F9FBFD;
      padding: 15px;
      border-radius: 8px;
  }

  .active_JFKP {
    color: #ffffff !important;
    border-bottom: 1px solid #177ED3;
    background: #177ED3;
    border-left: 1px solid #177ED3;
    border-right: 1px solid #177ED3;
}

.tab_phbU {
    color: #177ED3;
    border: 1px solid #177ED3;
    font-family: 'GT Haptik', sans-serif;
    font-weight: 400;
    text-decoration: none;
    text-align: center;
    font-size: 13px;
    display: flex;
    align-items: center;
    margin-right: 15px;
    cursor: pointer;
    white-space: nowrap;
    text-overflow: ellipsis;
    border-radius: 20px;
    padding: 6px 15px;
    gap: 5px;
    min-width: 160px;
    width: auto;
    height: 28px;
    justify-content: center;
  }

  .conten-filters-title-panel {
    height: 60px;
  }

  .content-result {
    display: flex;
    flex-direction: row;
    -webkit-box-pack: justify;
    justify-content: space-between;
  }

  .panel-content-result {
      display: flex;
      border-bottom: 1px solid #efeff5;
      box-shadow: 0px 4px 16px rgba(21, 84, 156, .08);
      flex-direction: column;
      align-items: center;
      justify-content:center;
      width: 20%;
      border-radius: 8px;
          background: #fff;
    }

  .panel-content-result .ttl-panel {
    font-size: 18px;
    color: #505068;
    font-weight: 600;
    text-align: center;
  }

  .panel-content-result .result-panel {
     font-size: 34px;
    color: #237CE1;
    line-height: 2;
    font-weight: 500;
  }
</style>
