<template>
  <div>
    <!-- Barra de progreso para un solo colegio -->
    <div class="progress mt-2" v-if="colegios.length">
      <div class="progress-bar"
        :style="{ width: colegios[0].positivos + '%' , backgroundColor: colores[0] }"
        :class="colores[0]">       
      </div>
      <div class="progress-bar"
        :style="{ width: colegios[0].neutros + '%' , backgroundColor: colores[1] }"
        :class="colores[1]">        
      </div>
      <div class="progress-bar"
        :style="{ width: colegios[0].negativos + '%' , backgroundColor: colores[2] }"
        :class="colores[2]">        
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProgressConvivencia',
  props: {
    colores: {
      type: Array,
      default: () => [], // Colores por defecto de Bootstrap
    },
    colegios: {
      type: Array,
      default: () => [], // Pasar un colegio
    },
  },
};
</script>

<style scoped>
/* Estilos adicionales opcionales */
.progress {
    height: 6px;
    background-color: #e9ecef;
    border-radius: .25rem;
    display: flex; /* Asegúrate de que las barras se alineen en fila */
}

.progress-bar {
    text-align: center;
    white-space: nowrap;
    color: #fff;
    transition: width 0.6s ease;
}
</style>