<template>
  <div class="wrapper-graph-dona">
    <div>      
      <canvas id="dona"></canvas>
    </div>
  </div>
</template>

<script>

import AuthMixin from '../mixins/auth';

export default {
  name: 'DoughnutChart',
  mixins: [
    AuthMixin,
  ],
  props: {
    chartData: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      chartInstance: null,
    };
  },
  mounted() {
    this.createChart();
  },
  watch: {
    chartData: {
      handler(newData) {
        if (this.chartInstance) {
          this.chartInstance.data = newData;
          this.chartInstance.update();
        }
      },
      deep: true,
    },
  },
  methods: {
    createChart() {
      const config = {
        type: 'doughnut',
        data: this.chartData,
        options: {
          rotation: Math.PI, // 180 degrees for half-doughnut
          circumference: Math.PI, // Show half-doughnut
          cutout: '60%', // Hole in the center
          maintainAspectRatio: false,
          legend: {
            position: 'right',
            padding: 20,
            labels: {
              usePointStyle: true,
            },
          },
          plugins: {
            legend: {
              display: false,
            },
          },
          elements: {
            arc: {
              // borderRadius: 10, // Border width for arcs
              borderAlign: 'inner', // Align borders to the inner part
              borderWidth: 5,
            },
          },
        },
      };

      const canvas = document.getElementById('dona');
      const ctx = canvas.getContext('2d');
      this.chartInstance = new Chart(ctx, config);
    },
  },
};
</script>

<style>

</style>
