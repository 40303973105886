<template>
  <div class="custom-card box_2gFm">
    <h2 class="card-title">{{ title }}</h2>

    <div class="card-content-all" v-for="(colegio, index) in schools" :key="index">
      <div style="width: 50%" class="card-content-shool">
        <div>
          <spam class="shool-box el-popover__reference">{{ colegio.name }}</spam>
        </div>
        <div class="card-content">
        <!--<spam class="value_2VkK">{{ (Number(colegio.positivos) || 0) + (Number(colegio.necesidad) || 0) + (Number(colegio.neutros) || 0) }}%</spam>-->
          <spam class="value_2VkK">{{ colegio.value }}%</spam>
        </div>
      </div>

      <div style="width: 50%">
        <progress-convivencia
          :colores="colors"
          :colegios="[colegio]"
        />
      </div>
    </div>

    <div class="color-palette">
    <div v-for="(color, index) in colors" :key="index" class="color-box-all">    
      <div class="color-box"
        :style="{ backgroundColor: color }"
      ></div>
      <spam>{{ index === 0 ? 'Positivo' : index === 1 ? 'Neutro' : index === 2 ? 'Negativo' : '' }}</spam>
      </div>
    </div>
  </div>
</template>

<script>
import ProgressConvivencia from '../administrator/administrator-progress';
export default {
  name: 'CustomCard',
  components: {
    ProgressConvivencia,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    colors: {
      type: Array,
      default: () => [], // Default colors
    },
    schools: {
      type: Array,
      default: () => [], // Default schools
    },
  },
};
</script>

<style scoped>
.custom-card {
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  text-align: center;
  margin-bottom: 20px;
  transition: background-color 0.3s ease;
}

.card-title {
  font-size: 24px;
  color: #333;
  margin-bottom: 10px;
}

.card-content {
    margin-right: 10%;
}

.color-palette {
  display: flex;
  justify-content: end;
  width: 100%;
}

.color-box {
  width: 20px;
  height: 20px;
  border-radius: 34%;
  margin: 0 5px;
}

.card-content-all {
    display: flex;
    align-items: center;
    justify-content: start;
    flex-wrap: wrap;
    width: 100%;
    margin-bottom: 10px;
}

.card-content-shool {
    display: flex;
    flex-direction: row;
    align-content: flex-end;
    justify-content: space-between;
    align-items: center;
}

p.shool-box {
    margin: 0;
    line-height: normal;
}

spam.shool-box.el-popover__reference {
    color: #177ED3;
}

.color-box-all {
    display: flex;
    align-items: center;
}
</style>