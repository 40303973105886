<template>
  <div class="wrapper-graph-line">
    <div>      
      <canvas id="line-chart"></canvas>
    </div>
  </div>
</template>

<script>

import AuthMixin from '../mixins/auth';

export default {
  name: 'LineChart',
  mixins: [
    AuthMixin,
  ],
  props: {
    chartData: {
      type: Object,
      default: null,
    },
    chartOptions: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      chartInstance: null,
    };
  },
  mounted() {
    this.createChart();
  },
  // watch: {
  //   chartData: {
  //     handler(newData) {
  //       if (this.chartInstance) {
  //         this.chartInstance.data = newData;
  //         this.chartInstance.update();
  //       }
  //     },
  //     deep: true,
  //   },
  //   chartOptions: {
  //     handler(newOptions) {
  //       if (this.chartInstance) {
  //         this.chartInstance.options = newOptions;
  //         this.chartInstance.update();
  //       }
  //     },
  //     deep: true,
  //   },
  // },
  methods: {
    handleDatasetVisibility(index) {
      console.log('ingreso a actulizar');
      const chart = this.$refs.barGraph.chartInstance;
      // Iterar sobre todos los datasets y ocultarlos
      chart.data.datasets.forEach((dataset, i) => {
        dataset.hidden = i !== index;  // Mostrar solo el dataset seleccionado
      });
      chart.update();  // Actualizar el gráfico
    },

    createChart() {
      const config = {
        type: 'line',
        data: this.chartData,
        options: {          
          ...this.chartOptions,
        },
      };

      const canvas = document.getElementById('line-chart');
      const ctx = canvas.getContext('2d');
      this.chartInstance = new Chart(ctx, config);
    },
  },
};
</script>

<style>

</style>
