<template>
  <paywall
    :permitted="isLoggedIn && hasGradesPermission"    
    :free="isFreeUser"
    restricted
    transparent>

    <rdr-results-header
      :show-school-selector="isLoggedIn"
      year-picker
      hide-school-picker
      show-school-picker
      show-school-year-picker
      show-planned-term-picker>
    </rdr-results-header>
    <router-view/>
    <tabs
      :tabs="bySchoolTabs"
      :tab-width="200"
      links/>
    <router-view/>
    <div class="conten-filters">
      <div class="ttl-panel">Evolución de los registros por mes</div>
    </div>
    <div id="content-attendance-e" class="results-colormap">      
      <div class="content-result">
        <div class="panel-content-result-e">
          <div class="ttl-panel">Total registros</div>
          <div class="result-panel">{{ this.totals.total }}</div>
        </div>
        <div class="panel-content-result-e">
          <div class="ttl-panel">Registros positivos</div>
          <div class="result-panel"> {{ this.totals.positivos }}</div>
        </div>
        <div class="panel-content-result-e">
          <div class="ttl-panel">Registros negativos</div>
          <div class="result-panel"> {{ this.totals.negativos }} </div>
        </div>
        <div class="panel-content-result-e">
          <div class="ttl-panel">Registros neutros</div>
          <div class="result-panel"> {{ this.totals.neutros }} </div>
        </div>
      </div>    
      <div v-if="loadGraph" class="contenGraficas-line-evolution">
        <div v-if="loading">
          <transition name="fade">
            <loading-overlay/>
          </transition>
        </div>
        <div v-else>
          <template v-if="loadGraph">
            <div class="content-justify-linechart">
              <line-chart
                :chart-data="dataReport"
                :chart-options="lineOptions"
              />
            </div>
          </template>
          <div class="content-filters">
             <div class="contentlegen">
              <div class="legen all" :class="{ active_legen: activeTab === null }" @click="toggleDatasetVisibility(null, true,'Todos')">
              </div>
              <span>Todos</span>
           </div>
              
            <div class="contentlegen">
              <div class="legen positivos" :class="{ active_legen: activeTab === 'Positivos' }" @click="toggleDatasetVisibility([4,5], false,'Positivos')">
              </div>
              <span>Positivos</span>
           </div>
           
           <div class="contentlegen">
              <div class="legen negativos" :class="{ active_legen: activeTab === 'Negativos' }" @click="toggleDatasetVisibility([0,1], false, 'Negativos')">
              </div>
              <span>Negativos</span>
            </div>

            <div class="contentlegen">
              <div class="legen neutros" :class="{ active_legen: activeTab === 'Neutros' }" @click="toggleDatasetVisibility([2,3], false, 'Neutros')" >
              </div>
              <span>Neutros</span>
             </div>
          </div>
        </div>
      </div>
    </div> 
  </paywall>
</template>

<script type="text/javascript" src="https://cdn.jsdelivr.net/npm/chart.js"></script>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
// import ColorMapFooter from '../../../components/results/color-map-footer.vue';
import ResultsHeader from '../../../components/results/results-attendance-header.vue';
import Tabs from '../../../components/tabs.vue';
import AuthMixin from '../../../mixins/auth';
import PermissionMixin from '../../../mixins/permission';
import ResultsMixin from '../../../mixins/results';
import MockDataMixin from '../../../mixins/mock-data';
import LineChart from '../../../components/line-gorup-chart.vue';

/* eslint-disable camelcase */

export default {
  name: 'coexistenceGPSchoolEvolucion',
  components: {
    //ColorMapFooter,
    'rdr-results-header': ResultsHeader,
    LineChart,
    'tabs': Tabs,
  },
  mixins: [AuthMixin, MockDataMixin, PermissionMixin, ResultsMixin],
  data() {
    return {
      loadGraph: true,
      activeTab: null,
      lineOptions: {
        responsive: true,
        maintainAspectRatio: false,
        layout: {
        padding: {
          top: 100,
        },
      },
      tooltips: {
        enabled: true,
        displayColors: true,
        callbacks: {
          mode: 'x',
        },
      },
      scales: {
        xAxes: [{
          gridLines: {
            display: false,
          },
          ticks: {
            beginAtZero: true,
          },
        }],
        yAxes: [{
          ticks: {
            beginAtZero: true,
          },
          gridLines: {
            borderDash: ['6', '2'],
            color: '#DCDCDC',
            fill: false,
          },
        }],
      },
      legend: {
        position: 'bottom',
        display: false,
        labels: {
          usePointStyle: true,
        },
        onClick: (e) => e.stopPropagation(),
      },
      hover: {
        animationDuration: 1,
      },
      elements: {
            line: {
              tension: 0, // Suavizar las curvas de las líneas
             // borderWidth: 30,
              backgroundColor: '#trasmparent', // Fondo de la línea transparente
            },
            point: {
              radius: 4,
              hitRadius: 8,
              hoverRadius: 6,
              backgroundColor: 'trasmparent', // Puntos sin color de fondo
            },
          },
      },
    };
  },
  computed: {
    ...mapState('results', {
      resultsLoading: 'loading',
    }),
    ...mapGetters('results', {
      resultsGetter: 'results',
    }),
    loading() { return !!this.resultsLoading; },
    generalPanelLink() {
      return { name: 'coexistenceGeneralPanelsSchoolCoexistence', params: this.$route.params };
    },
     coexistenceGPSchoolEvolucionLink() {
      return { name: 'coexistenceGPSchoolEvolucion', params: this.$route.params };
    },
     coexistenceGPSchoolComparisonLink(){
      return { name: 'coexistenceGPSchoolComparacion', params: this.$route.params };
    },
    coexistenceGPSchoolDistribucionLink() {
       return { name: 'coexistenceGPSchoolDistribucion', params: this.$route.params };
    },
    bySchoolTabs() {
      return [
        { icon: '', name: 'General', link: this.generalPanelLink },
        { icon: '', name: 'Evolución mensual', link: this.coexistenceGPSchoolEvolucionLink },
        { icon: '', name: 'Comparación', link: this.coexistenceGPSchoolComparisonLink },
        { icon: '', name: 'Distribución por fecha', link: this.coexistenceGPSchoolDistribucionLink },
      ];
    },
    dataReport() {
      if (this.resultsGetter.length > 0) {
        return this.resultsGetter[0].dataReport;
      } else {
        return {
          labels: [],
          datasets: []
          }
        }
      }, 
      totals() {
        if (this.resultsGetter.length === 0) {
          return {
            total: 0,
            positivos: 0,
            negativos: 0,
            neutros: 0,
          };
        }
        return this.resultsGetter[0].totals;
      },
  },
  methods: {
    ...mapActions('options', ['setReportParams']),
    setViewParams() {
      this.setReportParams({ 'school_ids': true, 'years': true, 'months': true });
      this.setReportKey('behavior-evolucion-school');
    },

    toggleDatasetVisibility(index, showAll = false) {
      if (index === null) {
        // Si se hace clic en "Todos", desactivar cualquier selección individual
        this.activeTab = null;
      } else {
        // Si se selecciona un botón específico, asignar su índice como activeTab
        // this.activeTab = this.activeTab === index ? null : index; // Alternar selección
        this.activeTab = index;
      }

      const chart = this.$refs.barGraph.chartInstance;  
      // Si el parámetro showAll es true, mostrar todos los datasets
      if (showAll) {
          chart.data.datasets.forEach(dataset => {
              dataset.hidden = false;  // Mostrar todos los datasets
          });
          chart.update();
          return;  // Salir de la función después de mostrar todos los datasets
      }

        // Verificar si todos los datasets están visibles
        const allVisible = chart.data.datasets.every(dataset => !dataset.hidden);    
        // Verificar si el dataset seleccionado ya es el único visible
        const selectedDataset = chart.data.datasets[index];
        const onlyOneVisible = chart.data.datasets.filter(dataset => !dataset.hidden).length === 1 && !selectedDataset.hidden;

        // Si todos los datasets están visibles y se hace clic en un dataset
        if (allVisible) {
            // Si el mismo dataset ya está visible, mostrar todos los datasets
            if (onlyOneVisible) {
                chart.data.datasets.forEach(dataset => {
                    dataset.hidden = false;  // Mostrar todos los datasets
                });
            } else {
                // Si el dataset no está visible, ocultar todos los demás y mostrar solo el seleccionado
                chart.data.datasets.forEach((dataset, i) => {
                    dataset.hidden = i !== index;  // Solo mostrar el dataset seleccionado
                });
            }
        } else {
            // Si no todos los datasets están visibles, mostrar el dataset seleccionado y ocultar los demás
            chart.data.datasets.forEach((dataset, i) => {
                // Si el índice es el seleccionado, mostrarlo
                if (i === index) {
                    dataset.hidden = false;
                } else {
                    dataset.hidden = true;  // Ocultar los demás
                }
            });
        }
    // Actualizar el gráfico para reflejar los cambios
    chart.update();
    }
  },
};
</script>
<style>
  /*@import "../../../styles/app/variables";*/

  .content-filters {
    display: flex;
    margin-bottom: 30px;
    margin-top: 30px;
    align-items: center;
    flex-direction: row;
    align-content: center;
    justify-content: center;
}

  div#content-attendance-e {
      padding: 15px;
      border-radius: 8px;
  }

  .contentlegen {
      display: flex;
      align-items: center;
      align-content: flex-start;
      justify-content: flex-start;
      margin-right: 15px;
      gap: 5px;
      font-size: 14px;
  }

  .contenGraficas-line-evolution .legen {
      height: 16px;
      width: 16px;
      border-radius: 50%;
  }
  .contenGraficas-line-evolution .legen.all {
      background: grey;
  }

  .contenGraficas-line-evolution .legen.positivos {
      background: #77BD25;
  }

  .contenGraficas-line-evolution .legen.negativos {
      background: #FF5454;
  }

  .contenGraficas-line-evolution .legen.neutros {
      background: #FFCF54;
  }


  .active_JFKP {
    color: #ffffff !important;
    border-bottom: 1px solid #177ED3;
    background: #177ED3;
    border-left: 1px solid #177ED3;
    border-right: 1px solid #177ED3;
}

.tab_phbU {
    color: #177ED3;
    border: 1px solid #177ED3;
    font-family: 'GT Haptik', sans-serif;
    font-weight: 400;
    text-decoration: none;
    text-align: center;
    font-size: 13px;
    display: flex;
    align-items: center;
    margin-right: 15px;
    cursor: pointer;
    white-space: nowrap;
    text-overflow: ellipsis;
    border-radius: 20px;
    padding: 6px 15px;
    gap: 5px;
    min-width: 160px;
    width: auto;
    height: 28px;
    justify-content: center;
  }

  .conten-filters {
    height: 60px;
  }

  .content-result {
    display: flex;
    flex-direction: row;
    -webkit-box-pack: justify;
    justify-content: space-between;
  }

  .panel-content-result-e {
      display: flex;
      border-bottom: 1px solid #efeff5;
      box-shadow: 0px 4px 16px rgba(21, 84, 156, .08);
      flex-direction: column;
      align-items: center;
      justify-content:center;
      width: 20%;
      border-radius: 8px;
      background: #EDF4FD;
    }

  .panel-content-result-e .ttl-panel {
    font-size: 18px;
    color: #505068;
    font-weight: 600;
    text-align: center;
  }

  .panel-content-result-e .result-panel {
     font-size: 34px;
    color: #237CE1;
    line-height: 2;
    font-weight: 500;
  }

 .content-justify-linechart {
    width: 100%;
    margin: 0 auto;
    position: relative;
  }

  .wrapper-graph-line canvas#line-chart {
    max-height: 350px;
    min-height: 350px;
}
</style>
