<template>
  <paywall
    :permitted="isLoggedIn && hasGradesPermission"
    :quote-text="quoteText"
    :quote-header="quoteHeader"
    :free="isFreeUser"
    restricted
    transparent>
    <div id="downTable" class="results-colormap">
       <span class="results-header__subtitle">
          Última actualización: 04 vista
        </span>
      <div class="results-colormap__color-map contenScroll">
        <color-map
          :loading="loading"
          :rows="groupsAsRows"
          :columns="subjectsAsColumns"
          :results="results"
          :hide-empty-columns="true"
          :show-school-picker="false"
          :disable-school-adding="true"
          :grades="true"
          :min-value="4"
          :max-value="7"
          :column-header-hover="false"
          results-row-key="group_id"
          column-sort="average_ascending"
          normalization-label="value"
        />
      </div>
      <share-whatsapp/>
      <template v-if="results.length !== 0">
        <color-map-footer
          :footer-bottom-paragraphs="footerParagraphs"
          text="La gama de colores representa las calificaciones de la siguiente forma:"
          high="7.0"
          average="5.5"
          low="4.0"/>
      </template>
    </div>
    <color-map
      slot="mock"
      :rows="mockGradesGroupAverageData.groups"
      :columns="mockGradesGroupAverageData.columns"
      :results="mockGradesGroupAverageData.results"
      :default-open-rows="mockGradesGroupAverageData.defaultOpenRows"
      :results-column-key="mockGradesGroupAverageData.resultsColumnKey"
      :results-row-key="mockGradesGroupAverageData.resultsRowKey"
      :disable-school-adding="true"
      class="color-map--pro"/>
  </paywall>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import ColorMapFooter from '../../../components/results/color-map-footer.vue';

import AuthMixin from '../../../mixins/auth';
import PermissionMixin from '../../../mixins/permission';
import ResultsMixin from '../../../mixins/results';
import MockDataMixin from '../../../mixins/mock-data';

/* eslint-disable camelcase */

export default {
  name: 'CoexistencePanelBySchoolStudentView',
  components: {
    ColorMapFooter,
  },
  mixins: [AuthMixin, MockDataMixin, PermissionMixin, ResultsMixin],
  data() {
    return {
      footerParagraphs: [
        'Las calificaciones son obtenidas a partir de la información de la plataforma de gestión de cada establecimiento.',
        'Los colores son referenciales para cada concepto y no necesariamente representan las metas internas de cada proyecto educativo.',
      ],
      quoteHeader: '¿Quieres visualizar las calificaciones y recibir alertas de posible repitencia?',
    };
  },
  computed: {
    ...mapState({
      loading: state => state.results.loading,
    }),
    ...mapGetters('options', ['groups']),
    ...mapGetters('results', {
      evaluations: 'resultsEvaluations',
      indicators: 'resultsIndicators',
      resultsGetter: 'results',
    }),
    quoteText() {
      if (this.isAptusAccount && !this.hasGradesPermission) {
        return 'Actualmente tienes el plan Asistencia en alianza con Aptus. Para acceder a las calificaciones del año en curso, contrata el plan Académico (que, además de la asistencia y evaluaciones Aptus, te permitirá ver un seguimiento de todas las calificaciones que se están poniendo en tu establecimiento y calcula automáticamente alertas por estudiante)';
      } else if (!this.hasGradesPermission) {
        return 'Actualmente tienes un plan que no permite hacer seguimiento de calificaciones. Para acceder a las calificaciones del año en curso, contrata el plan Académico (que te permitirá ver un seguimiento de todas las calificaciones que se están poniendo en tu establecimiento y calcula automáticamente alertas por estudiante).';
      }
      return '';
    },
    results() {
      return this.resultsGetter.map(result => {
        const indicator = this.indicators.find(i => i.id === result.indicator_id);        
        return { ...result, column_id: indicator.id };
      });
    },
    groupsAsRows() {
      return this.groups.map(group => ({
        id: group.id,
        key: group.key,
        label: group.short_name,
        parent_id: group.parent_id,
      }));
    },
    subjectsAsColumns() {
      const columns = [];

      this.indicators.forEach(indicator => {
        const newColumn = {
          id: indicator.id,
          label: indicator.label,
        };
        const columnExists = columns.find(column => column.id === newColumn.id);

        if (!columnExists) columns.push(newColumn);
      });

      return columns;
    },
  },
  methods: {
    ...mapActions('options', ['setReportParams']),
    setViewParams() {
      this.setReportParams({ 'school_ids': true, 'years': true, 'months': true });
      this.setReportKey('behavior');
    },
  },
};
</script>
