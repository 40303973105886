<template>
  <div :class="$style.resultsHeaderFilters">
    <div
      :class="$style.resultsHeader">
      <div>
        <div
          v-if="title"
          :class="$style.resultsHeaderTitle">
          {{ title }}
        </div>
        <div :class="$style.row">
          <school-logo v-if="showSchoolSelector && !freeOrGuestUser" />
          <!-- <div :class="$style.rowInner">-->
          <div>
          <div
              v-if="showSchoolSelector"
              :class="$style.resultsHeaderPickers"
            >           
              <label class="labelInput">Colegio:</label><br>
              <autocomplete-school
                v-model="chosenSchool"
                :options="schoolOptions"
                :loading="loading"
                :class="[$style.resultsHeaderSchoolPicker, $style.resultsHeaderSchoolRow]"
                label-filter="startCase"
                label-property="name"
                @input="schoolChange"
              />
            </div>
            <!--<div
              v-if="!showSchoolSelector"
              :class="[$style.resultsHeaderPickers]">-->

            <!--<div :class="[$style.resultsHeaderPickers]">// SE COMETA PARA VAIDAR DESPUES
              <label class="labelInput">Colegio:</label><br>
              <div class="contenInp">
                <span v-if="isLoggedIn && userNetworkName">{{ userNetworkName }}</span>
                <span v-else-if="isLoggedIn && schoolOptions.length > 0">Visión general colegios</span>
                <span v-else-if="chosenSchool">{{ chosenSchoolName | startCase }}</span>
                <span v-else>Cargando...</span>
              </div>
            </div>-->

            <div
              v-if="yearPicker"
              :class="$style.resultsHeaderPickers"
            >         
              <label v-if="showLabel" class="labelInput">Año:</label><br>
              <year-picker
                v-if="viweSelectAgno"
                :loading="loading"
                :icon-calendar="true"
                :reset-years="resetYear"
                @input="yearChange"
                @yeargeter
              />
            </div>
            <div
              v-if="groupPicker"
              :class="[$style.resultsHeaderPickers]">            
              <div v-if="viweSelect">
                <label v-if="showLabel" class="labelInput">Curso:</label><br>
                <group-picker
                  :only-result-groups="onlyResultGroups"
                  :only-children-groups="onlyChildrenGroups"
                  :loading="loading"
                  :donde="urlSite"
                />
              </div>
            </div>
          
            <div
              v-if="monthPicker"
              :class="$style.resultsHeaderPickers"
            >          
              <label class="labelInput">Mes</label><br>
              <!--<label v-if="showLabel" class="labelInput">{{ isMonths ? 'Mes:' : 'Período:' }}</label><br>-->
              <month-picker
                :custom-names="allMeses"
                :loading="loading"
                :icon-calendar="true"
                :donde="urlSite"
              />
            </div>

            <!-- <div
              v-if="indicatorPicker && indicatorPickerLabel !== 'Evaluación'"
              :class="$style.resultsHeaderPickers">
              <label v-if="showLabel" class="labelInput">{{ indicatorPickerLabel }}:</label><br>
              <indicator-picker
                :loading="loading"
                :donde="indicatorPickerLabel"/>
            </div> -->
            <!-- <div :class="$style.resultsHeaderSlotDescription">
              <div :class="$style.resultsHeaderSlot">
                <slot/>
              </div>

              <template v-if="reportDescription || viewAttendance">
                <report-description
                  :description="reportDescription"
                  :new-stayle="true"
                  :view-icon-print="true"
                  :view-icon-down="true"
                />
              </template>
            </div> -->
          </div>
        </div>
      </div>
      <!-- <button
        v-if="!hideSchoolPicker"
        :class="[{ 'mobile-only': mobileOnlyPicker }, $style.schoolPickerButton, $style.rdrBtn]"
        @click="openSchoolPicker">
        <span>{{ schoolPickerLabel }} FFFFF</span>
      </button> -->
    </div>

    <!-- <div v-if="viewTabs">
      <tabs
        v-if="tabs.length > 0"
        :tabs="tabs"
        :tab-width="tabWidth"
        links/>
    </div>  -->
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';
import ReportDescription from '../../components/results/report-description.vue';
import PermissionMixin from '../../mixins/permission';
// import Tabs from '../../components/tabs-hide.vue';
import SchoolLogo from '../school-logo';
import AutocompleteSchool from '../../components/utils/autocomplete.vue';

export default {
  name: 'RdrResultsHeader',
  components: {
    ReportDescription,
    SchoolLogo,
    // Tabs,
    AutocompleteSchool,
  },
  mixins: [PermissionMixin],
  data() {
    return {
      chosenType: {"id": "1", "name": "Anual"},
      viewTabs: true,
      viweFiltersAttendence: '1',   
      dataWeek: [],
      yearSelect: '',
      monthSelect: '',
      viweSelect: true,
      viweSelectAgno: true,
      urlSite: '',
      searchUrl: false,
      firstLoad: true,
      resetYear: false,
      viewAttendance: false,
      allMeses: [
        { label: 'Enero', value: 0 },
        { label: 'Febrero', value: 1 },
        { label: 'Marzo', value: 2 },
        { label: 'Abril', value: 3 },
        { label: 'Mayo', value: 4 },
        { label: 'Junio', value: 5 },
        { label: 'Julio', value: 6 },
        { label: 'Agosto', value: 7 },
        { label: 'Septiembre', value: 8 },
        { label: 'Octubre', value: 9 },
        { label: 'Noviembre', value: 10 },
        { label: 'Diciembre', value: 11 },
      ],
    };
  },
  props: {
    // =========PRÓXIMAS A SER DEPRECADAS=========
    isMonths: {
      type: Boolean,
      default: true,
    },
    yearPicker: {
      type: Boolean,
      default: false,
    },
    typePicker: {
      type: Boolean,
      default: false,
    },
    monthPicker: {
      type: Boolean,
      default: false,
    },
    groupPicker: {
      type: Boolean,
      default: false,
    },
    historicGroupPicker: {
      type: Boolean,
      default: false,
    },
    indicatorPicker: {
      type: Boolean,
      default: false,
    },
    indicatorPickerLabel: {
      type: String,
      default: 'Indicador',
    },
    schoolPickerLabel: {
      type: String,
      default: 'Agregar o cambiar colegios',
    },
    // ===========================================
    title: {
      type: String,
      default: '',
    },
    showSchoolSelector: {
      type: Boolean,
      default: false,
    },
    hideSchoolPicker: {
      type: Boolean,
      default: false,
    },
    mobileOnlyPicker: {
      type: Boolean,
      default: false,
    },
    tabs: {
      type: Array,
      default: () => [],
    },
    tabWidth: {
      type: Number,
      default: null,
    },
    monthNames: {
      type: Array,
      default: () => [],
    },
    onlyResultGroups: {
      type: Boolean,
      default: false,
    },
    onlyChildrenGroups: {
      type: Boolean,
      default: false,
    },
    showLabel: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapState('auth', ['isLoggedIn']),
    ...mapState('results', ['loading']),
    ...mapState({titleText: state => state.ui.tituloMod}),
    ...mapGetters('results', ['reportDescription']),
    ...mapGetters('auth', ['userSchools', 'userNetworkName']),
    ...mapGetters('options', {
      chosenSchoolFromState: 'chosenSchool',
      chosenSchools: 'chosenSchools',
    }),
    pickersNumber() {
        console.log(this.yearPicker, this.groupPicker, this.historicGroupPicker, this.indicatorPicker, this.monthPicker, 'VALORES');
      return [this.yearPicker, this.groupPicker, this.historicGroupPicker, this.indicatorPicker, this.monthPicker].filter(v => v).length;
    },
    chosenSchool: {
      get() {
        const vModelReturn = this.validateOptions();
        return vModelReturn;
      },
      async set(value) {
        this.addLoading();
        await this.setChosenSchool(value.id);
        this.removeLoading();
        this.$ga.event({
          eventCategory: 'SchoolSelector',
          eventAction: 'Pick',
          eventLabel: 'OptionSelect',
          eventValue: value.rbd,
        });
      },
    },
    schoolOptions() {
      if (this.chosenSchools.length > 0) return this.chosenSchools;

      return this.userSchools;
    },
    typeOptions() {
      const option = [
        {"id": "1", "name": "Anual"},
        {"id": "2", "name": "Mensual"},
        {"id": "3", "name": "Semanal"},
      ]
      return option;
    },
    chosenSchoolName() {
      return this.chosenSchool && this.chosenSchool.name;
    },
  },
  watch: {
    menuHover(newMenuHover, oldMenuHover) {
      this.renderLoadIframe();
    },
  },
  mounted() {
    this.viewAttendance = false;
    const URLactual = window.location.hash;
    // if(this.titleText.key === 'attendance') {
    //   setTimeout(() => {
    //     this.viewAttendance = true;
    //   }, 300);
    // }
    this.searchUrl = URLactual.includes('student/subject-averages');
    this.urlSite = '';
    if (this.searchUrl) {
      this.viweSelect = false;
      this.urlSite = 'subjectAverages';
      setTimeout(() => {
        this.viweSelect = true;
      }, 700);
    }
  },
  methods: {
    ...mapMutations('results', {
      addLoading: 'ADD_LOADING',
      removeLoading: 'REMOVE_LOADING',
    }),

    ...mapMutations('ui', { changeTypeAttendance: 'changeTypeAttendance' }),

    ...mapActions('ui', ['openDialog']),
    ...mapActions('options', ['setChosenSchool']),
    openSchoolPicker() {
      this.openDialog('schoolPicker');
    },

    getOptionType(infoData) {
      this.chosenType = infoData;
      // localStorage.setItem('type_attendance', infoData);
      this.changeTypeAttendance(infoData.id);
      if (infoData.id !== '1') {
        this.viewTabs = false;
      } else {
        this.viewTabs = true;
      }
      this.viweFiltersAttendence = infoData.id;
      if (infoData.id === '3') {
        this.dataWeek = [];
        this.weekMonth();
      }
    },

    weekMonth() {
      const dateLanding = new Date();
      if(this.yearSelect === '') {
        this.yearSelect = dateLanding.getFullYear()
      }
      if(this.monthSelect === '') {
        this.monthSelect = dateLanding.getMonth()
      }
      var firstDay = new Date(this.yearSelect, this.monthSelect-1, 1);
      var endDay  = new Date(this.yearSelect, this.monthSelect, 0);
      var used         = firstDay.getDay() + endDay.getDate();
      this.dataWeek = Math.ceil( used / 7);
    },
    yeargeter(data) {
      this.yearSelect = data;
    },
    yearChange() {
      this.resetYear = false;
      if (this.searchUrl) {
        this.viweSelect = false;
        setTimeout(() => {
          this.viweSelect = true;
        }, 700);
      }
    },
    schoolChange() {
      console.log('ingreso 01', this.searchUrl);
      this.resetYear = false;
      if (this.searchUrl) {
        console.log('ingreso 02', this.searchUrl);
        this.resetYear = true;
        this.viweSelectAgno = false;
        setTimeout(() => {
          this.viweSelectAgno = true;
        }, 700);
      }
    },
    monthgeter(data) {
      this.monthSelect = data;
      this.weekMonth();
    },

    validateOptions() {
      let returnOrigin = false;
      this.schoolOptions.forEach((value) => {
        if(value.id === this.chosenSchoolFromState.id) returnOrigin = true;
      });
      if (returnOrigin) return this.chosenSchoolFromState;
      else return this.schoolOptions[0];
    },
  },
};
</script>

<style lang="scss" module>
@import "../../../styles/app/variables";

.results-header {
  font-weight: 200;
  margin-bottom: 30px;

  @media only screen and (max-width: $mobile-breakpoint) {
    margin-bottom: 45px;
  }

  &-title {
    font-size: 28px;
    margin-bottom: 10px;
  }

  &-school,
  &-pickers {
    display: inline-block;
    font-size: 15px;
    padding-right: 15px;
  }

  .school-picker-button {
    margin-top: 15px;
  }

  &-slot-description {
    padding: 0 30px;
    display: flex;
    justify-content: center;
    font-size: 18px;
  }
}

.row {
  display: flex;
  flex-direction: row;
  align-items: center;

  &-inner {
    width: 100%;
    justify-content: space-between;
  }

  @media only screen and (max-width: $mobile-breakpoint) {
    flex-direction: column;
  }

}
</style>
