<template>
  <div class="report">
    <year-warning-bar v-if="hasAdministratorReportPermission" />
    <!-- <alert-info :text-alert="reportTitle" /> -->
    <div>
      <div :class="$style.reportHeader">
        <report-options
          :show-chosen-school-picker="false"
          :hide-school-logo="true"
          :report-key="reportKey">
        </report-options>
      </div>
      <paywall
        :permitted="hasAdministratorReportPermission"
        restricted>
        <div
          v-if="chosenSchoolIds.length > 0"
          :class="$style.reportContainer">
          <administrator-report-dashboard
            :dashboard="dashboard"
            :loading.sync="loading"
          />
        </div>
      </paywall>
    </div>
    Registros de Convivencia
    <div class="dashboard-elements-indicator-box_2rk4">
      <div class="item-content" v-for="(card, index) in cardsData" :key="index">
        <custom-card
          :title="card.title"
          :colors="card.colors"
          :schools="card.colegios"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import PermissionMixin from '../../mixins/permission';
import AuthMixin from '../../mixins/auth';
import ReportOptions from '../../components/report-options';
import Paywall from '../../components/paywall.vue';
import AdministratorReportDashboard from '../administrator/administrator-dashboard';
import customCard from '../administrator/administrator-convivencia';
import YearWarningBar from '../../components/utils/year-warning-bar.vue';
import AlertInfo from '../../components/utils/alert-info.vue';

export default {
  name: 'AdmininistratorReportView',
  components: {
    ReportOptions,
    AdministratorReportDashboard,
    Paywall,
    YearWarningBar,
    AlertInfo,
    customCard,
  },
  mixins: [PermissionMixin, AuthMixin],
  data() {
    return {
      dashboard: true,
      loading: 0,
      reportKey: 'administrator_report_school_results',
      reportTitle: 'En esta ficha encontrarás los resultados de tus colegios. Haz click en un colegio para ver detalles.',
      hideAlert: false,

      cardsData: [
        {
          title: 'Revenue',         
          colors: ['#5AAC27', '#FFCF54', '#FF4F56'],
          colegios: [
            {
              name: 'Colegio 1',
              positivos: 30,
              negativos: 25,
              neutros: 25,
              value: 80,
            },
            {
              name: 'Colegio 2',
              positivos: 40,
              negativos: 30,
              neutros: 20,
              value: 90,
            },
            {
              name: 'Colegio 3',
              positivos: 20,
              negativos: 10,
              neutros: 20,
              value: 50,
            },
            {
              name: 'Colegio 4',
              positivos: 50,
              negativos: 50,
              neutros: 0,
              value: 100,
            },
            ],
        },
        
      ],
    };
  },
  computed: {
    ...mapState('options', ['chosenSchoolIds']),
  },
};

</script>

<style lang="scss" module>
  .report-header {
    display: flex;
    flex-direction: column;
    align-items: start;
    text-align: start;
    font-size: 28px;
    font-weight: 200;
  }

  .item-content {
      width: 25%;
  }

</style>
