export default [
  {
    key: 'attendance',
    label: 'Asistencia',
    pro: true,
    icon: 'clg-asistencia',
    select: false,
    permissionsRequired: ['attendance'],
    children: [
      {
        key: 'attendance-comparative',
        label: 'Red de colegios',
        pro: true,
        link: 'attendanceComparativeLink',
        routes: ['comparativeAttendance'],
        tabActiv: false,
      },
      {
        key: 'attendance-per-school',
        label: 'Por colegio',
        pro: true,
        link: 'attendanceBySchoolLink',
        routes: ['attendanceBySchool'],
        tabActiv: false,
      },
      {
        key: 'attendance-per-student',
        label: 'Por estudiante',
        pro: true,
        link: 'attendanceByStudentLink',
        routes: ['attendanceByStudent'],
        tabActiv: false,
      },
    ],
  },
  {
    key: 'coexistence',
    label: 'Convivencia escolar',
    pro: true,
    icon: 'clg-asistencia',
    select: false,
    permissionsRequired: ['grades'],
    children: [
      {
        key: 'coexistence-network-school',
        label: 'Red de colegios',
        pro: true,
        link: 'coexistenceBySchoolNetworkLink',
        routes: ['coexistenceBySchoolNetwork'],
        tabActiv: false,
      },
      {
        key: 'coexistence-per-school',
        label: 'Por colegio',
        pro: true,
        link: 'coexistenceGeneralPanelsSchoolCoexistenceLink',
        routes: ['coexistenceBySchool'],
        tabActiv: false,
      },
      {
        key: 'coexistence-course-school',
        label: 'Por curso',
        pro: true,
        link: 'coexistenceBySchoolCourseLink',
        routes: ['coexistenceBySchoolCourse'],
        tabActiv: false,
      },
      // {
      //   key: 'coexistence-student-school',
      //   label: 'Por estudiante',
      //   pro: true,
      //   link: 'coexistenceBySchoolStudentLink',
      //   routes: ['coexistenceBySchoolStudent'],
      //   tabActiv: false,
      // },
    ],
  },
  {
    key: 'grades',
    label: 'Calificaciones',
    pro: true,
    icon: 'clg-diagnostico',
    select: false,
    permissionsRequired: ['grades'],
    children: [
      {
        key: 'grades-per-school',
        label: 'Por colegio',
        pro: true,
        link: 'gradesGroupAverageBySchoolLink',
        routes: ['gradesBySchool'],
        tabActiv: false,
      },
      {
        key: 'grades-per-student',
        label: 'Por estudiante',
        pro: true,
        link: 'gradesStudentSubjectAveragesLink',
        routes: ['gradesStudentSubjectAverages', 'gradesStudentSubjectTests'],
        tabActiv: false,
      },
    ],
  },
  {
    key: 'downloads',
    label: 'Descargas',
    pro: true,
    link: 'downloadsLink',
    icon: 'clg-download-cloud',
    select: false,
    permissionsRequired: ['attendance_excel_report', 'teacher_enrollment_csv'],
    routes: ['downloads'],
  },
  {
    key: 'reports',
    label: 'Fichas de trabajo',
    pro: true,
    icon: 'clg-guias-material',
    select: false,
    permissionsRequired: ['administrator_report', 'group_report', 'school_report', 'student_report'],
    children: [
      {
        key: 'reports-administrator-report',
        label: 'Ficha sostenedor',
        pro: true,
        link: 'administratorReportLink',
        alwaysShowToFreeUsers: true,
        permissionsRequired: ['administrator_report'],
        routes: ['administratorReport'],
        tabActiv: false,
      },
      {
        key: 'reports-school-report',
        label: 'Ficha colegio',
        pro: true,
        link: 'schoolReportLink',
        permissionsRequired: ['school_report'],
        routes: ['schoolReport'],
        tabActiv: false,
      },
      {
        key: 'reports-group-report',
        label: 'Ficha curso',
        pro: true,
        link: 'groupReportLink',
        permissionsRequired: ['group_report'],
        routes: ['groupReport'],
        tabActiv: false,
      },
      {
        key: 'reports-student-report',
        label: 'Buscador de estudiantes',
        pro: true,
        link: 'studentListLink',
        permissionsRequired: ['student_report'],
        routes: ['studentList', 'studentReport'],
        tabActiv: false,
      },
    ],
  },
  {
    key: 'financial',
    label: 'Financiero',
    pro: true,
    link: 'financialLink',
    icon: 'clg-informes',
    select: false,
    permissionsRequired: ['financial_module'],
    routes: ['financial'],
  },
  {
    key: 'external-evaluations',
    label: 'Evaluaciones Externas',
    pro: true,
    link: 'externalEvaluationsLink',
    icon: 'clg-sesion-clase',
    select: false,
    permissionsRequired: ['external_evaluations'],
    routes: ['externalEvaluations'],
  },
  // {
  //   key: 'internal-evaluations',
  //   label: 'Evaluaciones',
  //   pro: true,
  //   icon: 'clg-sesion-clase',
  //   select: false,
  //   permissionsRequired: ['internal_evaluations'],
  //   children: [
  //     {
  //       key: 'internal-evaluations-comparative',
  //       label: 'Red de colegios',
  //       pro: true,
  //       link: 'internalEvaluationsComparativeLink',
  //       routes: ['comparativeInternalEvaluations'],
  //       tabActiv: false,
  //     },
  //     {
  //       key: 'internal-evaluations-per-school',
  //       label: 'Por colegio',
  //       pro: true,
  //       link: 'internalEvaluationsBySchoolLink',
  //       routes: ['internalEvaluationsBySchool'],
  //       tabActiv: false,
  //     },
  //     {
  //       key: 'internal-evaluations-per-level',
  //       label: 'Por nivel',
  //       pro: true,
  //       link: 'internalEvaluationsByLevelLink',
  //       routes: ['internalEvaluationsByLevel'],
  //       tabActiv: false,
  //     },
  //     {
  //       key: 'internal-evaluations-per-student',
  //       label: 'Por alumno',
  //       pro: true,
  //       link: 'internalEvaluationsByStudentLink',
  //       routes: ['internalEvaluationsByStudent'],
  //       tabActiv: false,
  //     },
  //   ],
  // },
  {
    key: 'google-classroom-reports',
    label: 'Google Classroom',
    pro: true,
    icon: 'clg-grupo',
    select: false,
    permissionsRequired: ['google_classroom'],
    children: [
      {
        key: 'google-classroom-administrator-reports',
        label: 'Red de colegios',
        pro: true,
        permissionsRequired: ['google_classroom_administrator'],
        link: 'administratorGoogleClassroomLink',
        routes: ['administratorGoogleClassroom'],
        tabActiv: false,
      },
      {
        key: 'google-classroom-school-reports',
        label: 'Por colegio',
        pro: true,
        link: 'googleClassroomBySchoolLink',
        routes: ['googleClassroomBySchool'],
        tabActiv: false,
      },
    ],
  },
  {
    key: 'indicators',
    label: 'Indicadores de gestión',
    pro: true,
    icon: 'clg-star-doc',
    select: false,
    permissionsRequired: ['management_indicators'],
    children: [
      {
        key: 'indicators-dashboard-sip',
        label: 'Dashboard',
        pro: true,
        link: 'sipDashboardLink',
        permissionsRequired: ['sip_dashboard'],
        routes: ['sipDashboard'],
        tabActiv: false,
      },
      {
        key: 'indicators-enrollment',
        label: 'Matrícula - Ocupación',
        pro: true,
        link: 'enrollmentLink',
        routes: ['enrollment'],
        tabActiv: false,
      },
      {
        key: 'indicators-teacher-hours',
        label: 'Horas docencia',
        pro: true,
        link: 'teacherHoursLink',
        routes: ['teacherHours'],
        tabActiv: false,
      },
      {
        key: 'salesianos-leadership-evaluation',
        label: 'Evaluación Directiva',
        pro: true,
        permissionsRequired: ['salesianos_leadership_evaluation'],
        link: 'salesianosLeadershipEvaluationLink',
        routes: ['salesianosLeadershipEvaluation'],
        tabActiv: false,
      },
    ],
  },
  {
    key: 'public-results',
    label: 'Datos Públicos',
    icon: 'clg-informes',
    select: false,
    children: [
      {
        key: 'public-results-comparative',
        label: 'Red de colegios',
        link: 'comparativeLink',
        routes: ['comparative', 'comparativeColorMap'],
        tabActiv: false,
      },
      {
        key: 'public-results-per-school',
        label: 'Por colegio',
        link: 'schoolLink',
        routes: ['school', 'schoolHistorical'],
        tabActiv: false,
      },
      {
        permissionsRequired: ['psu_details'],
        key: 'psu-details',
        label: 'PAES (en detalle)',
        link: 'psuDetailsLink',
        routes: ['psuDetails'],
        tabActiv: false,
      },
      {
        permissionsRequired: ['technical_education_qualification'],
        key: 'technical-education-qualification',
        label: 'Titulación TP',
        link: 'technicalEducationQualificationLink',
        routes: ['technicalEducationQualification'],
        tabActiv: false,
      },
      {
        permissionsRequired: ['higher_education_graduation'],
        key: 'higher-education-graduation',
        label: 'Egreso a Ed. Superior',
        link: 'higherEducationGraduationLink',
        routes: ['higherEducationGraduation'],
        tabActiv: false,
      },
    ],
  },
  {
    key: 'improvement-plan',
    label: 'Seguimiento PME',
    pro: true,
    icon: 'clg-search',
    select: false,
    permissionsRequired: ['improvement_plan'],
    children: [
      {
        key: 'improvement-plan-comparative',
        label: 'Red de colegios',
        pro: true,
        link: 'improvementPlanComparativeLink',
        routes: ['improvementPlanComparative'],
        tabActiv: false,
      },
      {
        key: 'improvement-plan-per-school',
        label: 'Por colegio',
        pro: true,
        link: 'improvementPlanPerSchoolLink',
        routes: ['improvementPlanPerSchool'],
        tabActiv: false,
      },
    ],
  },

];
